import axios from 'axios';

export default {

    methods: {
       async readNFC() {
            try {
                this.psp_id = await this.getPaymentServiceProviderId();

                const response = await axios.post('/nfc/read', {
                    psp_id: this.psp_id,
                    reader: 'adyenterminal'
                });

                console.log(response)

                if (response.data.result == 'success') {
                    this.cardnumber = response.data.data.chipcard_id;
                    await this.checkCardnumber();
                } else {
                    this.mode = 'try-again';
                    console.log('readNFC failed')
                }
            } catch (error) {
                    console.log('readNFC error')
            } finally {
                // _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
            }
        },

        async abortNFC() {
            const response = await axios.post('/nfc/abort', {
                psp_id: this.psp_id,
                reader: 'adyenterminal'
            });

            if (response.result == 'success') {
                // TODO
                alert('abort success');
                // this.close(true);
            } else {
                console.log('abort failed')
            }
        },

        async getCashfunction () {
            await axios.get('/cashfunctions/wallet').then(response => {

                if (response.data.data == 'failed') {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Kan geen kaart opwaarden",
                        text: "Geen kasfunctie bekend voor wallets",
                    }, 3000);
                    return;
                }
                this.cashfunction = response.data.data;
            }).catch(() => {
                console.log('error');
            }).finally(() => {
                this.loading = false;
            });
        },

        async getPaymentServiceProviderId() {

            return await axios.get('/paymenttypes').then(response => {

                if (response.data.data == 'failed') {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",
                    }, 3000);
                    return;
                }
                const paymenttype = response.data.data.find(paymenttype => paymenttype.attributes.psp_type === 'adyen_pos' && paymenttype.attributes.psp_id);

                if (!paymenttype) {
                    alert('No payment service provider found!')
                    return false;
                }

                return paymenttype.attributes.psp_id;

            }).catch(() => {
                console.log('error');
            }).finally(() => {
                this.loading = false;
            });
        },
    },
}
