<template>
    <div id="numpad">

        <div class="flex table-number-input grid grid-cols-3 gap-2" v-if="!pinonly">
            <div class="relative col-span-2 h-full">

                <div  type="text" class="block h-full w-full fake-input" id="numpad-input" @change="$emit('inputchanged', value)">
                    {{ formatted_value }}
                </div>

<!--                <input v-if="formatted == true" type="text" v-model="formatted_value" :readonly="readonly" class="h-full w-full" id="numpad-input"/>-->
<!--                <input v-else type="text" v-model="value" @change="$emit('inputchanged', value)" :readonly="readonly" class="block w-full h-full" id="numpad-input"/>-->
            </div>
            <a @click="clearInput()" class="col-span-1 relative flex items-center justify-center button button-normal">
                <span>{{$t('translations.components.numpad.clear')}}</span>
            </a>
        </div>

        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('1')" class="button button-blur w-full block">
                    1
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('2')" class="button button-blur w-full block">
                    2
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('3')" class="button button-blur w-full block">
                    3
                </a>
            </span>
        </div>
        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('4')" class="button button-blur w-full block">
                    4
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('5')" class="button button-blur w-full block">
                    5
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('6')" class="button button-blur w-full block">
                    6
                </a>
            </span>
        </div>

        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('7')" class="button button-blur w-full block">
                    7
                </a>
            </span>
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('8')" class="button button-blur w-full block">
                    8
                </a>
            </span>
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('9')" class="button button-blur w-full block">
                    9
                </a>
            </span>
        </div>


        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('00')" class="button button-blur w-full block">
                    00
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('0')" class="button button-blur w-full block">
                    0
                </a>
            </span>
            <span v-if="times || pinonly" class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('x')" class="button button-blur w-full block">
                    X
                </a>
            </span>
        </div>
    </div>
</template>

<script>

import BarcodeReader from '../mixins/barcode.reader.js';

export default {
    props: ['default', 'formatted', 'times', 'clear', 'readonly', 'allowScanner', 'pinonly'],
    emits: ['inputchanged', 'scanned'],
    mixins: [BarcodeReader],
    name: 'Numpad',
    components: {
    },
    data() {
        return {
            value: this.formatted == true ? this.default * 100 : this.default,
            prestine: true,
        }
    },
    methods: {
        setInput(value, hasBarcode = false) {
            if (this.prestine) {
                this.prestine = false
                this.value = ''
            }

            console.log(value);

            if (value == 'x' && this.pinonly == true) {
                this.value = 0;
            }

            // this.value = Math.round(this.value * 100)


            if (hasBarcode === false) {
                this.value += value;
            } else {
                this.value = value;
            }

            if (value == 'x' && this.pinonly == true) {
                this.value = '';
            }

            this.$emit('inputchanged', this.value)
        },
        clearInput() {
            this.value = ''

            this.$emit('inputchanged', this.value)
        },

        barcodeScanned(barcode) {
            if (this.allowScanner === true) {

                this.$emit('scanned', barcode)

                if (barcode.length > 0) {
                    this.setInput(barcode);
                }
            }
        },
    },

    mounted () {

    },

    computed: {
        formatted_value() {

            if (this.value) {
                if (this.allowScanner) {
                    return this.value;
                }

                return parseFloat(this.value / 100).toFixed(2)
            }
            return null
        }
    },

    watch: {
        clear: function() {
            this.clearInput()
        }
    }
}
</script>
