export default {
    data () {
        return {
            attributes: {},
        }
    },
    mounted () {
        this.initBarcodeScanner()
    },
    unmounted () {
        this.destroyBarcodeScanner()
    },
    methods: {
        initBarcodeScanner() {
            let options = {}
            this.attributes = {
                previouseCode: '',
                barcode: '',
                setting: {
                    sound: false,
                    soundSrc: '',
                    scannerSensitivity: 100
                },
                callback: this.barcodeScanned,
                hasListener: false,
                pressedTime: []
            }

            // initial plugin setting
            if (options) {
                this.attributes.setting.sound = options.sound || this.attributes.setting.sound
                this.attributes.setting.soundSrc = options.soundSrc || this.attributes.setting.soundSrc
                this.attributes.setting.scannerSensitivity = options.sensitivity || this.attributes.setting.scannerSensitivity
            }

            this.addListener('keypress')
            this.addListener('keydown')
        },

        destroyBarcodeScanner() {
            this.removeListener('keypress')
            this.removeListener('keydown')
        },

        addListener (type) {
            if (this.attributes.hasListener) {
                this.removeListener(type)
            }
            window.addEventListener(type, this.onInputScanned)
            this.attributes.hasListener = true
        },

        removeListener (type) {
            if (this.attributes.hasListener) {
                window.removeEventListener(type, this.onInputScanned)
                // this.attributes.hasListener = false
            }
        },

        onInputScanned (event) {
            // ignore other keydown event that is not a TAB, so there are no duplicate keys
            if (event.type === 'keydown' && event.keyCode != 9 ) {
                return
            }

            if (this.checkInputElapsedTime(Date.now())) {
            if ((event.keyCode === 13 || event.keyCode === 9) && this.attributes.barcode !== '') {
                // scanner is done and trigger Enter/Tab then clear barcode and play the sound if it's set as true
                this.attributes.callback(this.attributes.barcode)
                // backup the barcode
                this.attributes.previousCode = this.attributes.barcode
                // clear textbox
                this.attributes.barcode = ''
                // clear pressedTime
                this.attributes.pressedTime = []
                // trigger sound
                if (this.attributes.setting.sound) {
                    this.triggerSound()
                }
                // prevent TAB navigation for scanner
                if (event.keyCode === 9) {
                    event.preventDefault()
                }
                } else {
                // scan and validate each charactor
                this.attributes.barcode += event.key
                }
            }
        },

        checkInputElapsedTime (timestamp) {
            // push current timestamp to the register
            this.attributes.pressedTime.push(timestamp)
            // when register is full (ready to compare)
            if (this.attributes.pressedTime.length === 2) {
                // compute elapsed time between 2 keystrokes
                let timeElapsed = this.attributes.pressedTime[1] - this.attributes.pressedTime[0];
                // too slow (assume as human)
                if (timeElapsed >= this.attributes.setting.scannerSensitivity) {
                // put latest key char into barcode
                this.attributes.barcode = event.key
                // remove(shift) first timestamp in register
                this.attributes.pressedTime.shift()
                // not fast enough
                return false
                }
                // fast enough (assume as scanner)
                else {
                // reset the register
                this.attributes.pressedTime = []
                }
            }
            // not able to check (register is empty before pushing) or assumed as scanner
            return true
        },

        triggerSound () {
            let audio = new Audio(this.attributes.setting.soundSrc)
            audio.play()
        },
    }
}
